import React, { useContext } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import './CardSection.css';
import { CartContext } from '../../contexts/CartProvider';


const CardSection = () => {
  const { setCreditCardComplete } = useContext(CartContext);


  return (
    <PaymentElement onChange={({ complete }) => setCreditCardComplete(complete)}/>
  );
};

export default CardSection;
