import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { STRIPE_KEY } from '../../config';

const stripePromise = loadStripe(STRIPE_KEY);

const StripeComponent = ({ clientSecret }) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret.clientSecret
  };

  return (
    <div
      style={{
        marginTop: '20px',
        marginRight: '20px',
        marginBottom: '30px',
      }}
    >
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default StripeComponent;
