import React, { useContext } from 'react';
import { CartContext } from '../../contexts/CartProvider';
import EZInput from '../EZInput/EZInput';
import StripeComponent from '../StripeComponent/StripeComponent';
import './PaymentMethod.css';

const PayWithCheck = (props) => {
  const { checkSelected, setCheckSelected, orderInfo } =
    useContext(CartContext);
  const { checkCustomer } = orderInfo;

  const handleCheckSelection = (e) => {
    setCheckSelected(e.target.value === 'check');
  };

  if (checkCustomer) {
    return (
      <div className="pay-with-check">
        <div>
          <input
            type="radio"
            id="checkSelected"
            name="cardcheck"
            checked={checkSelected}
            value="check"
            onChange={handleCheckSelection}
          />
          <label htmlFor="checkSelected">Pay with a Check</label>
        </div>
        <div>
          <input
            type="radio"
            id="cardSelected"
            name="cardcheck"
            checked={!checkSelected}
            value="card"
            onChange={handleCheckSelection}
          />
          <label htmlFor="cardSelected">Pay with a Credit Card</label>
        </div>
      </div>
    );
  }

  return null;
};

const PaymentMethod = (clientSecret) => {
  const { nameOnCard, checkSelected, setNameOnCard } = useContext(CartContext);
  const handleChangeNameOnCard = (e) => {
    setNameOnCard(e.target.value);
  };

  return (
    <div className="payment-method">
      <div className="payment-content">
        <div className="payment-title">Payment Method</div>

        {/*Need to add logic here to :
            3. Place Order should call the /vi/payment/check endpoint instead of going to stripe.*/}

        <PayWithCheck />

        {checkSelected || (
          <>
          {/* <EZInput
              className="payment"
              label="Name on Card"
              labelClassName="card-information-title"
              name="nameOnCard"
              value={nameOnCard}
              handleValueChange={handleChangeNameOnCard}
            /> */}
            <StripeComponent clientSecret={clientSecret} />
            
          </>
        )}
      </div>
      <div className="payment-method-bottom"></div>
    </div>
  );
};

export default PaymentMethod;
