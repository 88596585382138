import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './CheckoutPage.css';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import PaymentMethod from '../../components/PaymentMethod/PaymentMethod';
import { REVIEW_CART_ROUTE } from '../../routes';
import CartErrorModal from '../../components/CartErrorModal/CartErrorModal';
import { CartContext } from '../../contexts/CartProvider';

const CheckoutPage = (props) => {
  const history = useHistory();
  const { orderInfo } = useContext(CartContext);
  const { setShowSubheader, setShowCart } = useContext(AuthenticationContext);
  const { clientSecret } = orderInfo;

  useEffect(() => {
    setShowCart(false);
    setShowSubheader(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!clientSecret) {
    history.push(REVIEW_CART_ROUTE);
    return null;
  }

  return (
    <div className="checkout-page">
      <div className="checkout-main-content">
        <div className="checkout-title">Checkout</div>
        <PaymentMethod clientSecret={clientSecret} />
      </div>

      <CartErrorModal />
      <OrderSummary actionLabel="Place Order" />
    </div>
  );
};

export default CheckoutPage;
