import React, { useContext, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import CardSection from '../CardSection/CardSection';
import { useState } from 'react';
import { CartContext } from '../../contexts/CartProvider';

export default function CheckoutForm({ clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();

  const { setStripeObject, setStripeElements } = useContext(CartContext);

  useEffect(() => {
    setStripeObject(stripe);
    setStripeElements(elements);
  }, [stripe, elements]);

  return <CardSection />;
}
