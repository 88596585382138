import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BACKEND_URL, JWT_KEY, PEKELACH_ORDERING_DONE } from '../../config';
import {
  CartContext,
  SHALOCH_CUSTOMER_IDS,
  // SHALOCH_MANOS_PRICE,
} from '../../contexts/CartProvider';
import { getWithExpiry } from '../../services/localStorageService';
import { currencyDisplay } from '../../services/util';
import './OrderSummary.css';
import PacmanLoader from 'react-spinners/PacmanLoader';

import {
  addItemOrders,
  calculateTotal,
  plularize,
  removePekalach,
} from './OrderSummaryUtil';
import { CHECKOUT_ROUTE, CONFIRMATION_ROUTE } from '../../routes';
import { payByCheck } from '../../services/paymentService';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';

const PekalaLineItem = ({ itemId, name, price }) => {
  const { cart } = useContext(CartContext);
  if (!cart.items[itemId]) {
    return null;
  }

  return (
    <div className="in-cart-items-line-item">
      <div className="in-cart-items-line-item-top-line">
        <span className="line-item-label">
          {removePekalach(name)}({cart.items[itemId]})
        </span>
        <span className="line-item-value">
          {currencyDisplay(price * cart.items[itemId])}
        </span>
      </div>
      <div className="in-cart-items-line-item-bottom-line">
        {currencyDisplay(price)} each
      </div>
    </div>
  );
};

const OrderSummary = ({ actionLabel }) => {
  const {
    numOfShalochManosItems,
    itemsInCart,
    pekalachs,
    numOfPekelachItems,
    cart,
    stripeObject: stripe,
    stripeElements: elements,
    nameOnCard,
    reciprocityFlag,
    setCartErrorMessage,
    creditCardComplete,
    checkSelected,
    orderInfo,
    setOrderInfo,
    defaultGreeting,
    greetingSuffix,
    price
  } = useContext(CartContext);

  const {
    clientSecret,
    checkCustomer,
    itemOrderGroup,
    orderGroup,
    pricePerOrder,
    stripePaymentId,
    year,
  } = orderInfo;

  const isBtnDisabled = () => {
    if (actionLabel === 'Checkout') {
      return (
        itemsInCart === 0 ||
        (numOfShalochManosItems > 0 && (!defaultGreeting || defaultGreeting.length === 0))
      );
    }

    return (!creditCardComplete) && !checkSelected;
  };

  const history = useHistory();
  const [obtainingPaymentToken, setObtainingPaymentToken] = useState(false);
  const [submittingPayment, setSubmittingPayment] = useState(false);

  const handleSubmitOrder = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!clientSecret) {
      setCartErrorMessage('Stripe Token Missing');
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setSubmittingPayment(true);

    let result = {};

    if (checkSelected) {
      await payByCheck({
        checkCustomer,
        clientSecret,
        itemOrderGroup,
        orderGroup,
        pricePerOrder,
        stripePaymentId,
        year,
      }).catch((err) => {
        console.log(`Error: ${err.message} ${err.stack}`);
        result.error = `Error making pay by check call: ${err.message}`;
      });
      result.paymentIntent = {};
      result.paymentIntent.status = 'succeeded';
    } else {
      result = await stripe.confirmPayment({
        elements,
        redirect: 'if_required'
      });
    }

    if (result.error) {
      setSubmittingPayment(false);
      setCartErrorMessage(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        setTimeout(() => {
          setSubmittingPayment(false);
          history.push(CONFIRMATION_ROUTE);
        }, 300);
      } else {
        setSubmittingPayment(false);
        setCartErrorMessage(
          'Could not process your payment - please try again later!'
        );
      }
    }
  };

  const handleCheckout = () => {
    setObtainingPaymentToken(true);
    const result = {};
    result.defaultGreeting = defaultGreeting;
    result.greetingSuffix = greetingSuffix;
    result.reciprocity = reciprocityFlag;

    if (numOfPekelachItems > 0) {
      addItemOrders(result, cart, pekalachs);
    }

    result.orders = [];
    if (numOfShalochManosItems > 0) {
      result.orders = cart[SHALOCH_CUSTOMER_IDS].map((toCustomerId) => ({
        toCustomerId: parseInt(toCustomerId),
      }));
    }
    result.year = 2021;
    fetch(`${BACKEND_URL}api/v1/payment`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
      body: JSON.stringify(result),
    })
      .then((response) => response.json())
      .then((data) => {
        setOrderInfo(data);
        history.push(CHECKOUT_ROUTE);
      })
      .catch((error) => console.log('No way! ', error));
  };

  const cartEmptyMessage = PEKELACH_ORDERING_DONE
    ? 'cart is empty'
    : 'the cart is empty';

  return (
    <div className="order-summary">
      <div>
        <div className="order-summary-title">Order Summary</div>
        {Boolean(itemsInCart) || (
          <div className="empty-carts">{cartEmptyMessage}</div>
        )}

        {Boolean(numOfPekelachItems) && (
          <div className="in-cart-items">
            <div className="in-cart-items-subtitle">pekelach</div>
            {pekalachs.map((pekala) => (
              <PekalaLineItem {...pekala} key={pekala.itemId} />
            ))}
          </div>
        )}

        {Boolean(numOfShalochManosItems) && (
          <div className="in-cart-items">
            <div className="in-cart-items-subtitle">mishloach manos</div>
            <div className="in-cart-items-line-item">
              <div className="in-cart-items-line-item-top-line">
                <span className="line-item-label">
                  {numOfShalochManosItems}{' '}
                  {plularize('recipient', numOfShalochManosItems)}
                </span>
                <span className="line-item-value">
                  {currencyDisplay(
                    numOfShalochManosItems * price
                  )}
                </span>
              </div>
              <div className="in-cart-items-line-item-bottom-line">
                {currencyDisplay(price)} each
              </div>
            </div>
          </div>
        )}

        <div className="total">
          <div>Total:</div>
          <div className="total-amt">
            {currencyDisplay(calculateTotal(cart, pekalachs, price))}
          </div>
        </div>
        <div className="checkout-container">
          <button
            className="checkout"
            disabled={isBtnDisabled()}
            onClick={
              actionLabel === 'Checkout' ? handleCheckout : handleSubmitOrder
            }
          >
            {obtainingPaymentToken || submittingPayment ? (
              <div
                style={{
                  width: '50px',
                  marginTop: '-10px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <PacmanLoader size={10} loading={true} color={'white'} />
              </div>
            ) : (
              <span>{actionLabel}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
